import fs from 'fs';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import FourOFour from '../components/ui/404';

export async function getServerSideProps(_context: any) {
    const fileName = _context.query.page;
    try {
        const content = fs.readFileSync(`./src/assets/files/${fileName}.md`, 'utf-8');
        return {
            props: {
                content
            }
        };
    } catch (error) {
        console.error(error);
        return {
            props: {
                content: null
            }
        };
    }
}

function Page({ content }: any) {
    if (!content) return <FourOFour className="!h-calc-212" />;
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="container mt-4 sm:mt-8">
                <Markdown className="markdown-body max-w-[1000px]" remarkPlugins={[remarkGfm]}>
                    {content}
                </Markdown>
            </div>
        </div>
    );
}

export default Page;
